import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import client from '../../../apollo';
import DatePicker from 'react-datepicker';
import {
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Select,
  Checkbox,
  Input
} from 'semantic-ui-react';
import {
  utcMoment,
  dateTimeFormattedWithOutTimeZone
} from '../../../utilities/dateUtils';
import { get, isEmpty, toNumber } from 'lodash';
import Validator from 'validatorjs';
import customerDealerGroupQuery from '../../../graphql/customerDealerGroupQuery';
import ExceptionForm from '../Form';

class GroupExceptionForm extends ExceptionForm {
  state = {
    gracePeriod: '',
    groupName: '',
    exceptionEndTime: null,
    exceptionEndTimeTemp: '',
    exceptionEndTimeString: '',
    noEndDate: false,
    groupId: null,
    groupNames: [],
    isLoading: false,
    debounceTimeout: null,
    searchQuery: '',
    action: 'Create',
    groupNameOptions: [],
    error: null,
    errors: {}
  };

  componentDidMount() {
    const { groupException, groups } = this.props;
    const {
      id,
      exceptionSourceId,
      exceptionSourceName,
      gracePeriod,
      exceptionEndTime,
      createdBy,
      createdAt,
      updatedBy,
      updatedAt
    } = groupException;
    const action = id === 0 ? 'Create' : 'Update';
    const groupNameOptions = this.groupNameOptions(groups);
    this.setState({
      id: toNumber(id),
      groupId: exceptionSourceId,
      groupName: exceptionSourceId,
      gracePeriod: gracePeriod,
      exceptionEndTime: exceptionEndTime,
      exceptionEndTimeTemp: exceptionEndTime,
      exceptionEndTimeString: this.initExceptionEndTimeString(exceptionEndTime),
      createdBy: createdBy,
      createdAt: createdAt,
      updatedBy: updatedBy,
      updatedAt: updatedAt,
      isNoEndDate: this.initIsNoEndDate(id, exceptionEndTime),
      groupNameOptions: groupNameOptions,
      groupNameOptionsCache: groupNameOptions,
      action: action
    });
  }

  onChangeHandler = field => event => {
    this.setState({ [field]: event.target.value });
  };

  groupNameSearchQuery = keyword => {
    if (!isEmpty(keyword)) {
      client
        .query({
          query: customerDealerGroupQuery,
          variables: {
            groupName: keyword,
            page: 1,
            size: 200
          }
        })
        .then(({ data }) => {
          let groupNames = get(data, 'customerDealerGroupList');
          if (groupNames) {
            this.setState({
              groupNameOptions: this.groupNameOptions(groupNames),
              isLoading: false
            });
          } else {
            this.setState({
              groupNameOptions: [],
              isLoading: false
            });
          }
        })
        .catch(error => {
          this.setState({ groupNameOptions: [], isLoading: false });
        });
    } else {
      // reset to the location from the load first
      this.setState({
        groupNameOptions: this.groupNameOptions(this.props.groups),
        isLoading: false
      });
    }
  };

  groupNameOptions = groupNames => {
    if (!isEmpty(groupNames)) {
      return groupNames.map(function(group) {
        return {
          value: group.groupId,
          text: group.groupName
        };
      });
    }
  };

  handleSearchChange = (e, { searchQuery }) => {
    // Cancel the previous timer (if any)
    if (this.state.debounceTimeout) {
      clearTimeout(this.state.debounceTimeout);
    }

    // Set a new timer to delay the API call by 1 second
    const debounceTimeout = setTimeout(() => {
      this.groupNameSearchQuery(this.state.searchQuery);
    }, 1000);

    this.setState({
      groupId: null,
      groupName: null,
      searchQuery: searchQuery,
      isLoading: true,
      debounceTimeout: debounceTimeout
    });
  };

  onSelectHandler = field => (event, { value }) => {
    event.preventDefault();
    this.setErrorByField(field, ''); // reset error
    const { groupNameOptions } = this.state;
    const groupName = groupNameOptions.find(o => o.value === value);
    if (groupName) {
      this.setState({ [field]: value, groupId: value });
    } else {
      this.setState({ [field]: null, groupId: null });
    }
  };
  onClickSelect = () => {
    const { groupNameOptions, groupNameOptionsCache } = this.state;
    if (isEmpty(groupNameOptions)) {
      this.setState({ groupNameOptions: groupNameOptionsCache });
    }
  };
  submitForm = () => {
    this.setState({
      error: null,
      errors: {}
    });
    const { onSubmitHandler } = this.props;
    const {
      id,
      groupId,
      groupName,
      gracePeriod,
      exceptionEndTime,
      exceptionEndTimeString,
      action
    } = this.state;
    const validationRules = {
      groupName: 'required',
      gracePeriod: 'required|integer|min: 0|max: 60'
    };
    const customerErrorMessage = {
      'min.gracePeriod': 'Grace Period must be greater than or equal to zero.',
      'max.gracePeriod': 'Grace Period must be less than or equal to 60.'
    };

    const validation = new Validator(
      this.state,
      validationRules,
      customerErrorMessage
    );
    let messErrorDate = this.validateEndDateDate(exceptionEndTimeString);
    if (!isEmpty(messErrorDate) | validation.fails()) {
      let newErrors = {
        ...validation.errors.errors,
        exceptionEndTime: messErrorDate
      };
      this.setState({ errors: newErrors });
      return false;
    } else {
      onSubmitHandler({
        id: parseInt(id, 10),
        groupId: groupId,
        gracePeriod: gracePeriod || '',
        exceptionEndTime: isEmpty(exceptionEndTime)
          ? ''
          : dateTimeFormattedWithOutTimeZone(utcMoment(exceptionEndTime)),
        action: action
      });
    }
  };

  render() {
    const { isModalOpen, onCloseHandler, error, submitting } = this.props;
    const {
      gracePeriod,
      errors,
      exceptionEndTime,
      isNoEndDate,
      groupName,
      groupId,
      groupNameOptions,
      isLoading,
      action
    } = this.state;

    const enableDisableDatePickerClassName =
      isNoEndDate === true ? 'input-disabled' : '';
    const isDisableGroupName = action === 'Update';
    const disabledClassName =
      action === 'Update'
        ? 'selection-override input-disabled pre-wrap-dropdown'
        : 'selection-override pre-wrap-dropdown';

    return (
      <div>
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            closeIcon
            onClose={onCloseHandler}
            closeOnEscape={false}
            closeOnRootNodeClick={false}
          >
            <Modal.Header style={headerStyle}>
              {action === 'Create' ? action : 'Edit'} Group Exception
            </Modal.Header>
            <Modal.Content image>
              <Modal.Description>
                {error && (
                  <Message negative>
                    <p>{error}</p>
                  </Message>
                )}
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field required error={!isEmpty(errors.groupName)}>
                      <label>Group Name</label>
                      <Select
                        placeholder="Input your Group Name"
                        options={groupNameOptions}
                        closeOnBlur
                        search
                        loading={isLoading}
                        value={groupName}
                        onInput={this.handleInput(100).bind(this)}
                        onChange={this.onSelectHandler('groupName').bind(this)}
                        onSearchChange={this.handleSearchChange.bind(this)}
                        onClick={this.onClickSelect.bind(this)}
                        disabled={isDisableGroupName}
                        className={disabledClassName}
                      />
                      {errors.groupName && (
                        <div style={{ color: 'red' }}>{errors.groupName}</div>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label>Group ID</label>
                      <Input
                        name="groupId"
                        value={groupId || ''}
                        type="text"
                        disabled={true}
                        className="input-disabled"
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field required error={!isEmpty(errors.gracePeriod)}>
                      <label>Grace Period</label>
                      <input
                        name="gracePeriod"
                        value={gracePeriod}
                        type="number"
                        min={0}
                        max={60}
                        pattern="\d*"
                        onChange={this.onChangeHandler('gracePeriod').bind(
                          this
                        )}
                        onKeyDown={this.onFormatInput}
                      />
                      {errors.gracePeriod && (
                        <span style={{ color: 'red' }}>
                          {errors.gracePeriod &&
                          Array.isArray(errors.gracePeriod) &&
                          errors.gracePeriod.length > 1
                            ? errors.gracePeriod[errors.gracePeriod.length - 1]
                            : errors.gracePeriod}
                        </span>
                      )}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      required
                      error={!isEmpty(errors.exceptionEndTime)}
                    >
                      <label>Exception End Date</label>
                      <Checkbox
                        onClick={this.toggleEndDate}
                        label="No end date"
                        checked={isNoEndDate}
                      />
                      <DatePicker
                        className={enableDisableDatePickerClassName}
                        style={{ padding: '0', background: 'red' }}
                        selected={
                          !isEmpty(exceptionEndTime) &&
                          moment(exceptionEndTime).isValid()
                            ? utcMoment(exceptionEndTime)
                            : null
                        }
                        onChange={this.onDateChangeHandler('exceptionEndTime')}
                        onBlur={this.onDateFocusHandler(
                          'exceptionEndTimeString'
                        ).bind(this)}
                        dateFormat="YYYY-MM-DD"
                        disabled={isNoEndDate}
                        value={isNoEndDate ? '' : null}
                        minDate={this.today}
                      />
                      {errors.exceptionEndTime && (
                        <span style={{ color: 'red' }}>
                          {errors.exceptionEndTime}
                        </span>
                      )}
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              {submitting && (
                <Button loading positive>
                  Submitting...
                </Button>
              )}
              {!submitting && (
                <Button color="green" onClick={this.submitForm} inverted>
                  <Icon name="checkmark" /> {action}
                </Button>
              )}
              <Button onClick={onCloseHandler}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
      </div>
    );
  }
}

const headerStyle = {
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto auto'
};

export default compose(withRouter)(GroupExceptionForm);
