import { gql } from 'react-apollo';

const assignGroupDealerMutation = gql`
  mutation assignedGroupDealer($groupId: Int!, $dealerNumber: [Int!]!) {
    assignedGroupDealer(
      input: { groupId: $groupId, dealerNumber: $dealerNumber }
    ) {
      success
      code
      message
      data {
        groupId
        dealerNumber
        status
        message
        isImported
      }
    }
  }
`;

export default assignGroupDealerMutation;
