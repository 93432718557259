import { gql } from 'react-apollo';

const unassignGroupDealerMutation = gql`
  mutation unassignedGroupDealer($groupId: Int!, $dealerNumber: [Int!]!) {
    unassignedGroupDealer(
      input: { groupId: $groupId, dealerNumber: $dealerNumber }
    ) {
      success
      code
      message
      data {
        groupId
        dealerNumber
        status
        isRemoved
      }
    }
  }
`;

export default unassignGroupDealerMutation;
