export default {
  pathList: [
    '/internal/tappy/auctions',
    '/internal/tappy/blacklist/vin',
    '/internal/tappy/blacklist/dealer',
    '/internal/tappy/blacklist/auction_access',
    '/internal/tappy/dealers',
    '/internal/tappy/eligibility/verify',
    '/internal/tappy/floorplans/branches',
    '/internal/tappy/floorplans/companies',
    '/internal/tappy/guarantees',
    '/internal/tappy/network_plus',
    '/internal/tappy/returns',
    '/internal/tappy/subscriptions/dealer',
    '/internal/tappy/subscriptions/uid',
    '/internal/tappy/subscriptions/discover',
    '/internal/tappy/subscriptions',
    '/internal/tappy/transaction/fetch_external_id',
    '/internal/replayChargeEvent/externalAuction/{vin}',
    '/internal/replayChargeEvent/externalAuction'
  ]
};
