import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Confirm, Message } from 'semantic-ui-react';

import withPagination from './WithPagination';
import Search from '../views/DealerManager/Search';
import List from '../views/DealerManager/List';
import dealerListQuery from '../graphql/dealerListQuery';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';
import AssignForm from '../views/DealerManager/AssignForm';
import { graphql } from 'react-apollo';
import unassignGroupDealerMutation from '../graphql/unassignGroupDealerMutation';
import assignGroupDealerMutation from '../graphql/assignGroupDealerMutation';

export const GROUP_ID_EMPTY = 999999;

export const ResponseMessage = {
  Assign: {
    READY: 'Successfully assigned dealer to group',
    FAILED: 'Failed to assign dealer to group'
  },
  Unassign: {
    READY: 'Successfully unassigned dealer from group',
    FAILED: 'Failed to unassign dealer from group'
  }
};

export const AssignDealerStatus = {
  READY: 'READY',
  FAILED: 'FAILED',
  WARNING: 'warning'
};

export const DealerInfoAction = {
  ASSIGN__UNASSIGN: 'Assign/Unassign group',
  HANDLE_ASSIGN: 'Assign',
  HANDLE_UNASSIGN: 'Unassign'
};

class DealerManager extends Component {
  state = {
    dealerNumber: '',
    isDealer: true,
    dealerError: '',
    handleAssign: false,
    handleUnassign: false,
    selectedDealer: null,
    assignActionMsg: '',
    assignActionSuccess: false
  };

  dealerSearchQuery = event => {
    const { dealerNumber } = event.target;
    if (dealerNumber.value && !/^5[0-9]{6,6}$/i.test(dealerNumber.value)) {
      this.setState({
        isDealer: false,
        dealerError: 'Must be a valid Dealer Number'
      });
    } else {
      this.setState({ isDealer: true, dealerError: '' });
    }
    const variableArguments = {
      dealer_no: dealerNumber.value
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  onSelectDealerAction = (dealer, action) => {
    switch (action) {
      case DealerInfoAction.HANDLE_ASSIGN:
        this.setState({
          handleAssign: true,
          selectedDealer: dealer
        });
        break;
      case DealerInfoAction.HANDLE_UNASSIGN:
        this.setState({
          handleUnassign: true,
          selectedDealer: dealer
        });
        break;
      default:
        this.setState({
          selectedDealer: dealer
        });
        break;
    }
  };

  onExcuteMutationDealer = async (mutation, mutationName, action, payload) => {
    const response = await mutation({
      variables: { ...payload }
    });
    if (response && response.data) {
      let { data, message } = response.data[mutationName];
      if (data) {
        let isSuccess = false;
        let msg = '';
        if (
          [
            DealerInfoAction.HANDLE_ASSIGN,
            DealerInfoAction.HANDLE_UNASSIGN
          ].includes(action)
        ) {
          const recordStatus = record =>
            record.status === AssignDealerStatus.READY &&
            (record.isImported || record.isRemoved)
              ? AssignDealerStatus.READY
              : AssignDealerStatus.FAILED;
          const status = recordStatus(data[0]);
          isSuccess = recordStatus(data[0]) === AssignDealerStatus.READY;
          msg = ResponseMessage[action][status];
        }
        this.onSuccessSubmit(msg, isSuccess);
      } else {
        this.handleError({ message });
      }
      this.props.data.refetch();
    }
  };

  onSuccessSubmit = (msg, actionStatus) => {
    this.setState({
      assignActionSuccess: actionStatus,
      assignActionMsg: msg,
      handleAssign: false,
      handleUnassign: false,
      selectedDealer: null
    });
  };

  onHandleSubmitDealer = async (groupId, action, payload) => {
    const { unassignMutation, assignMutation } = this.props;
    if (!this.state.selectedDealer) return;

    const input = {
      groupId: groupId || this.state.selectedDealer.groupId,
      dealerNumber: [Number(this.state.selectedDealer.customerNumber)]
    };
    try {
      switch (action) {
        case DealerInfoAction.HANDLE_ASSIGN:
          this.onExcuteMutationDealer(
            assignMutation,
            'assignedGroupDealer',
            action,
            input
          );
          break;

        case DealerInfoAction.HANDLE_UNASSIGN:
          this.onExcuteMutationDealer(
            unassignMutation,
            'unassignedGroupDealer',
            action,
            input
          );
          break;
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  handleError = error => {
    this.setState({
      assignActionSuccess: null,
      assignActionMsg: Array.isArray(error) ? error : error.message,
      handleUnassign: false,
      handleAssign: false,
      selectedDealer: null
    });
  };

  handleCloseUnassign = () => {
    this.setState({ handleUnassign: false });
  };

  onCloseAssignForm = () => {
    this.setState({ handleAssign: false });
  };

  handleMessageDismiss = () => {
    this.setState({ assignActionMsg: false });
  };

  render() {
    const {
      data: { customerDealerList },
      searchFields
    } = this.props;

    const {
      isDealer,
      dealerError,
      handleAssign,
      handleUnassign,
      selectedDealer,
      assignActionMsg,
      assignActionSuccess
    } = this.state;

    return (
      <div>
        {assignActionMsg && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={assignActionMsg}
            positive
            negative={!assignActionSuccess}
          />
        )}
        <Button color="green" inverted>
          Create Dealer Information
        </Button>
        <br />
        <br />
        <Search
          onSubmit={this.dealerSearchQuery}
          searchFields={searchFields}
          searchOptions={['dealerNumber']}
        />
        {!isDealer && <span style={{ color: 'red' }}>{dealerError}</span>}
        <br />
        <br />
        <List
          dealerList={customerDealerList}
          onSelectDealer={this.onSelectDealerAction}
        />
        {selectedDealer && (
          <AssignForm
            openForm={handleAssign}
            onSubmit={this.assignActionHandle}
            onCloseForm={this.onCloseAssignForm}
            submitForm={this.onHandleSubmitDealer}
          />
        )}
        {selectedDealer && (
          <Confirm
            open={handleUnassign}
            content={`Are you sure you want to unassign this dealer from group ${selectedDealer.groupName}?`}
            onCancel={this.handleCloseUnassign}
            onConfirm={() =>
              this.onHandleSubmitDealer('', DealerInfoAction.HANDLE_UNASSIGN)
            }
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields }
  } = state;
  return {
    searchFields
  };
};

export default compose(
  withRouter,
  withPagination(dealerListQuery, 'customerDealerList'),
  connect(mapStateToProps, mapDispatchToProps),
  graphql(assignGroupDealerMutation, { name: 'assignMutation' }),
  graphql(unassignGroupDealerMutation, { name: 'unassignMutation' })
)(DealerManager);
