import React, { Component } from 'react';
import { Form, Button, Modal, Header, Icon, Dropdown } from 'semantic-ui-react';
import client from '../../apollo';
import customerDealerGroupQuery from '../../graphql/customerDealerGroupQuery';
import { filter, get, isEmpty } from 'lodash';
import Validator from 'validatorjs';
import { DealerInfoAction } from '../../containers/DealerManager';

class AssignForm extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    isLoading: false,
    groups: [],
    initialGroups: [],
    selectedGroupId: null,
    dealerGroup: null,
    debounceTimeout: null,
    searchQuery: '',
    groupNameError: null
  };

  handleSearchChange = (e, { searchQuery }) => {
    this.setState({ groups: [] });

    if (this.state.debounceTimeout) {
      clearTimeout(this.state.debounceTimeout);
    }

    const debounceTimeout = setTimeout(() => {
      this.groupNameSearchQuery(this.state.searchQuery);
    }, 1000);

    this.setState({
      groupId: null,
      groupName: null,
      searchQuery: searchQuery,
      isLoading: true,
      debounceTimeout: debounceTimeout
    });
  };

  componentDidMount() {
    this.groupNameSearchQuery();
  }

  groupNameSearchQuery = keyword => {
    client
      .query({
        query: customerDealerGroupQuery,
        variables: {
          groupName: keyword,
          page: 1,
          size: 200
        }
      })
      .then(({ data }) => {
        let groupData = get(data, 'customerDealerGroupList');
        if (groupData && groupData.length) {
          const groups = groupData.map(group => ({
            key: group.groupId,
            value: group.groupId,
            text: group.groupName
          }));
          this.setState({
            groups: groups,
            isLoading: false
          });
          if (!keyword && !this.state.initialGroups.length) {
            this.setState({ initialGroups: groups });
          }
        } else {
          this.setState({
            isLoading: false
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
      });
  };

  onHandleSelect = field => (event, { value }) => {
    let dealerGroup = this.state.groups.find(group => group.value === value);
    this.setState({
      selectedGroupId: value,
      groupNameError: null,
      dealerGroup
    });
  };

  onSubmitForm = () => {
    const validation = new Validator(this.state, {
      dealerGroup: 'required'
    });
    if (validation.fails()) {
      this.setState({
        groupNameError: validation.errors.errors.dealerGroup
      });
    } else {
      this.setState({
        groupNameError: null,
        selectedGroupId: null,
        dealerGroup: null
      });
      this.props.submitForm(
        this.state.selectedGroupId,
        DealerInfoAction.HANDLE_ASSIGN
      );
    }
  };

  onGetInitRevertData = () => {
    if (this.state.initialGroups) {
      this.setState({ groups: this.state.initialGroups });
      if (this.state.dealerGroup) {
        const selectedGroup = this.state.initialGroups.find(
          group => group.value === this.state.dealerGroup.value
        );
        if (selectedGroup) {
          this.setState({ selectedGroupId: selectedGroup.value });
        }
      }
    }
  };

  onCloseHandle = () => {
    this.setState({
      groupNameError: null,
      selectedGroupId: null,
      dealerGroup: null
    });
    this.props.onCloseForm();
  };

  render() {
    const { openForm } = this.props;
    const { isLoading, groups, selectedGroupId, groupNameError } = this.state;

    return (
      <Modal open={openForm}>
        <Modal.Header>Assign Group</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Header size="small" color="blue" />
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(groupNameError)}>
                  <label>Dealer Group</label>
                  <Dropdown
                    placeholder="Input your Group Name"
                    options={groups}
                    fluid
                    selection
                    search
                    loading={isLoading}
                    value={selectedGroupId}
                    onChange={this.onHandleSelect('groupName').bind(this)}
                    onSearchChange={this.handleSearchChange}
                    onClose={this.onGetInitRevertData}
                    onOpen={this.onGetInitRevertData}
                    selectOnBlur={false}
                    noResultsMessage="No result found"
                    className="short-scrolling-dropdown pre-wrap-dropdown"
                  />
                  {groupNameError && (
                    <div style={{ color: 'red' }}>{groupNameError}</div>
                  )}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {/* {isLoading && (
            <Button loading positive>
              Submitting...
            </Button>
          )} */}

          <Button color="green" onClick={this.onSubmitForm} inverted>
            <Icon name="checkmark" /> Submit
          </Button>

          <Button onClick={this.onCloseHandle}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default AssignForm;
